<template>
  <router-view/>
</template>

<script>
import home from './components/sidebar.vue';

export default {
  components:{
    home,
  }
}
</script>
<style>
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>