import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Sidebar',
      component: () => import('../components/sidebar.vue')
    },
    {
      path: '/hotList/:id',
      name: 'Detail',
      component: () => import('../components/detail.vue')
    },
    {
      path: '/headlineHotList',
      name: 'HeadlineHotList',
      component: () => import('../components/toutiao/headlineHotList.vue')
    },
    {
      path: '/caiJinHotList',
      name: 'CaiJinHotList',
      component: () => import('../components/toutiao/caiJinHotList.vue')
    },
    {
      path: '/kejiHotList',
      name: 'KejiHotList',
      component: () => import('../components/toutiao/kejiHotList.vue')
    },
    {
      path: '/tiyuHotList',
      name: 'TiyuHotList',
      component: () => import('../components/toutiao/tiyuHotList.vue')
    },
    {
      path: '/guojiHotList',
      name: 'GuojiHotList',
      component: () => import('../components/toutiao/guojiHotList.vue')
    },
    {
      path: '/junshiHotList',
      name: 'JunshiHotList',
      component: () => import('../components/toutiao/junshiHotList.vue')
    },
    {
      path: '/tuijinHotList',
      name: 'TuijinHotList',
      component: () => import('../components/toutiao/tuijinHotList.vue')
    },
    {
      path: '/yuleHotList',
      name: 'YuleHotList',
      component: () => import('../components/toutiao/yuleHotList.vue')
    },
    {
      path: '/lishiHotList',
      name: 'LishiHotList',
      component: () => import('../components/toutiao/lishiHotList.vue')
    },
    {
      path: '/meishiHotList',
      name: 'MeishiHotList',
      component: () => import('../components/toutiao/meishiHotList.vue')
    },
    {
      path: '/lvyouHotList',
      name: 'LvyouHotList',
      component: () => import('../components/toutiao/lvyouHotList.vue')
    },
    {
      path: '/shipingHotList',
      name: 'ShipingHotList',
      component: () => import('../components/toutiao/shipingHotList.vue')
    },
  ]
});

router.afterEach((to) => {
  document.title = '热播新闻';
});

export default router;