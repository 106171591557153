// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App'
import store from './store'
import router from './router'


import ElementUI from 'element-ui';//导入element-ui
import 'element-ui/lib/theme-chalk/index.css';//引入全部组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import 'bootstrap/dist/css/bootstrap.css'

// 阿里巴巴矢量图
import './assets/iconalibb/iconfont.css'

Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI);
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });//设置全局配置对象



Vue.use(VueRouter)
// Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
