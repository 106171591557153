import request from "../utils/request.js";
import qs from "qs";
 
const baseUrl = '/api/v1'
 
 
//获取新闻列表
export function xinWenList() {
  return request({
    url: baseUrl + "/world_news_api1",
    method: "get",
  });
}

//获取今日头条热榜
export function hotList() {
  return request({
    url: baseUrl + "/world_news_api1/hot_list",
    method: "get",
  });
}

//获取山西新闻
export function sanXiList() {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list",
    method: "get",
  });
}

//根据新闻id获取对应详情
export function xinWenById(id) {
    return request({
      url: baseUrl + "/world_news_api1/"+id,
      method: "get",
    });
  }

  //根据新闻url查询对应的文章
export function sanXiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/detail",
    method: "get",
    params,
  });
}

//根据新闻url查询财经对应的文章
export function caiJinListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/cai_jin_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询科技对应的文章
export function kejiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/ke_ji_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询体育对应的文章
export function tiyuListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/ti_yu_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询国际对应的文章
export function guojiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/guo_ji_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询军事对应的文章
export function junshiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/jun_shi_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询推荐对应的文章
export function tuijinListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/tui_jia_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询娱乐对应的文章
export function yuleListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/yu_le_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询历史对应的文章
export function lishiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/li_shi_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询美食对应的文章
export function meishiListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/mei_shi_detail",
    method: "get",
    params,
  });
}
//根据新闻url查询旅游对应的文章
export function lvyouListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/lv_you_detail",
    method: "get",
    params,
  });
}

//根据新闻url查询视频对应的文章
export function shipingListDetail(params) {
  return request({
    url: baseUrl + "/world_news_api1/shanxi_list/shi_ping_detail",
    method: "get",
    params,
  });
}
//退出
export function authLogout(params) {
  return request({
    url: baseUrl + "/logout",
    method: "get",
  });
}
//获取用户数据
export function getUserInfo(params) {
  return request({
    url: baseUrl + "/getUserInfo",
    method: "get",
    params:qs.stringfy(params)
  });
}