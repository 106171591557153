<template>
  <el-container class="home-container">
    <el-main style="padding: 0;">
      <div class="show-monitor show-monitor-block">
        <div role="banner" aria-label="背景大图">
          <div class="home-banner-wrapper">
            <div class="home-banner" :style="{ transform: 'translate3d(0px, 0px, 0px)' }">
              <!-- <video ref="backgroundVideo"
                src="https://lf3-static.bytednsdoc.com/obj/eden-cn/uldnupqplm/fangchunzhijianshipin.mp4" muted loop
                playsinline preload="auto" autoplay class="background-video"></video> -->
            </div>
            <el-container class="search-container new-style">
              <el-header class="search-wrapper main-search white">
                <el-link class="logo" aria-label="热播新闻" href="/" :underline="false"></el-link>
                <div>
                  <el-input v-model="searchValue" aria-label="搜索" autofocus class="search">
                    <el-button style="border: none;" slot="append" icon="el-icon-search" @click="handleSearch">
                    </el-button>
                  </el-input>
                </div>
              </el-header>
            </el-container>
          </div>
        </div>
      </div>
      <div role="banner" aria-label="头部" class="toutiao-header">
        <div class="header-left">
          <div class="download-app-wrapper">
            <a href="" target="_blank" rel="noopener nofollow">关于热播新闻</a>
            <a rel="nofollow" role="button">反馈</a><a href="" target="_blank" rel="noopener nofollow">侵权投诉</a>
          </div>

        </div>
        <div class="header-right common-component-wrapper">

          <div class="notification-container">
            <div aria-label="消息通知" class="ttp-header-notice dark">
              <a class="notice-icon" rel="nofollow">消息</a>
            </div>
          </div>
          <!-- <div aria-label="发布作品" class="publisher-icon">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2BAMAAAB+a3fuAAAAMFBMVEVHcEz////////////////////////////////////////////////wQULxTU73mpuw3LHGAAAADHRSTlMA799wQJ8agLpfoM+GoKk6AAABPklEQVQ4y5WUrU4DQRDHp1DaFNWEgKrgASpKgqxoUosADQIUtgrFA1Sc6ANg8Fgc5rZtOMIEnmDhBfh4AWjoHd3bmb2dIfzd5JfMzs7HH6DU8LiLZrwPVdWP8FfbgxCdd9DJTDha9yiEjQSpzClhe8i15VETQ41KdlJhc4c2sKrDFbuMsPtV/RhTr2BnUbZTsG6UZTlqIYpJ1wSWJ73y4fPHF6+UPPdtX32QLudG0rzbFxINYJNE1lrWmprI2nAgshn0RTaFRGRzhT2W31tYp0+3NeBaYr1ccxx7IuztHyyaU6vlTvxDBjcK03qm9VqbkTZbbSegI+yS4ZfCdvBBPAfEXfGMEAtDSKLI/Hlj2m1qN81aE3oBm1PgIar3aJ4FDe4HGfU6aFGY9riBEpheVDz5WvTk3MvHSy+/JV7+A8tEjs/vnGz4AAAAAElFTkSuQmCC"
              alt="">
            <a rel="nofollow">发布作品</a>
          </div> -->
          <a class="login-button">登录</a>
        </div>
      </div>

      <div style="padding-left: 20%;margin-right: 21%;">
        <el-row :gutter="24">
          <el-col :span="16">
            <el-header id="qqhome-channel-header" style="display: flex;padding-left: 0;padding-right: 0; ">
              <el-menu class="channel-header" mode="horizontal" :default-active="activeIndex" @select="handleSelect"
                style="border-bottom: none !important;">
                <div class="qqhome-content-wrap" style="display: flex;justify-content: flex-start;">
                  <el-menu-item v-for="(item, index) in navItems" :key="index" :index="item.name">
                    <a target="_blank" style="color: #000 !important;font-weight: bold;font-size: 17px;">{{ item.name
                      }}</a>
                  </el-menu-item>
                </div>
              </el-menu>
            </el-header>
            <swiper class="swiper" :options="swiperOption" style=" border-radius: 10px;">
              <swiper-slide v-for="item in newsXinWenItems" :key="item.article_id">
                <img :src="item.image" :alt="item.title" style="width: 110%; height: 400px;">
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </el-col>
          <el-col :span="8" style="margin-left: 190px;">
            <!-- <span style="font-size: 20px;font-weight: 700;">今日头条热榜</span> -->
            <el-card class="news-ticker" style="  border-radius: 10px;border: 0px solid #c4c4c4;" shadow="never">
              <div slot="header" class="clearfix" style="padding-top: 0;">
                <i class="iconfont icondaochu1024-07" style="color: red;"></i>
                <span style="font-size: 20px;font-weight: 700;">今日热搜榜</span>
              </div>
              <div class="news-container" ref="newsContainer">
                <transition-group name="news-list" tag="ul">
                  <li v-for="(news, rowIndex) in visibleNews" :key="Number(news.ClusterIdStr)" style="margin-left: 0;">
                    <a :href="news.Url" target="_blank"> {{ rowIndex + 1 }}. {{ news.Title }}</a>
                  </li>
                </transition-group>
              </div>
            </el-card>
          </el-col>
        </el-row>


        <!-- <el-row :gutter="24" style="margin-top: 20px;">
          <el-col :span="12" style="width: 50%;display: flex;padding-left: 0;">

          </el-col>
          <el-col :span="12">
            <el-card class="news-ticker" style="  border-radius: 10px;">
              <div slot="header" class="clearfix">
                <span>今日头条热榜</span>
              </div>
              <div class="news-container" ref="newsContainer">
                <transition-group name="news-list" tag="ul">
                  <li v-for="(news, rowIndex) in visibleNews" :key="Number(news.ClusterIdStr)">
                    <a :href="news.Url" target="_blank"> {{ rowIndex + 1 }}. {{ news.Title }}</a>
                  </li>
                </transition-group>
              </div>
            </el-card>
          </el-col>
        </el-row> -->
        <div class="card-title" style="margin-top: 15px;display: flex;justify-content: space-between;">
          <div class="clearfix" style="width: 100%;display: flex;">

            <span class="title"> <el-divider direction="vertical"></el-divider>热点精选</span>
            <div href="/" target="_blank" :underline="false" class="float-right el-link el-link--default">
              更多
              <i class="el-icon-arrow-right el-icon--right"></i>
            </div>
          </div>
          <div>
          </div>
        </div>
        <el-row v-for="(row, rowIndex) in chunkedNewsItems" :key="rowIndex" :gutter="20"
          style="display: flex; justify-content: flex-start; margin-bottom: 20px;width: 85% !important;">
          <el-col v-for="item in row" :key="item.article_id" :span="6">
            <el-card :body-style="{ padding: '0px' }" class="news-card" @click.native="goToAbout(item.article_id)">
              <img :src="item.image" class="image" style="width: 100%;">
              <div style="padding: 14px;">
                <h3 style="color: #999;">{{ item.title }}</h3>
                <div class="bottom clearfix">
                  <span>{{ item.views }}次浏览</span>
                  <span style="margin-left: 10px;"> {{ formatCreatedAt(item.createdAt) }}</span>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>

    </el-main>
  </el-container>

</template>

<script>
// 使用ES6的import语法
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { xinWenList, hotList } from "../api/xinwen";
import moment from 'moment';
import 'swiper/css/swiper.css'
export default {
  name: 'HomeComponent',
  data() {
    return {
      activeName: '推荐',
      headlineLink: 'https://www.ixigua.com/7389452290479882790?id=7389565148535226918&logTag=d0a193170f8441acf309',
      searchValue: '',
      activeIndex: '推荐',
      mainTitle: '热点新闻',
      subTitle: '副标题',
      newsXinWenItems: [
        {
          id: 1,
          imgSrc: "https://news.gitapp.cn/api/upload/cover/1720232758564.jpeg",
          title: "学校举办2024年中外合作办学高质量发展交流会",
          views: 48,
          date: "2024-07-06 10:15:45"
        },
      ],
      navItems: [
        { name: '首页', link: '' },
        // { name: '推荐', link: '' },
        { name: '山西', link: '' },
        { name: '财经', link: '' },
        { name: '科技', link: '' },
        { name: '体育', link: '' },
        { name: '国际', link: '' },
        { name: '军事', link: '' },
        { name: '娱乐', link: '' },
        { name: '历史', link: '' },
        { name: '美食', link: '' }
        /**  { name: '美食', link: '' },
         { name: '旅游', link: '' },
         { name: '视频', link: '' },
         { name: '健康', link: '' },
         { name: '教育', link: '' },
         { name: '股票', link: '' },
         { name: '数码', link: '' },
         { name: '电竞', link: '' },
         { name: '旅游', link: '' },
         { name: '历史', link: '' },
         { name: '足球', link: '' },
         { name: '眼界', link: '' }*/
      ],
      carouselItems: [],
      newsItems: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      visibleNewsCount: 10,
      currentNewsIndex: 9
    }
  },
  mounted() {
    this.startNewsScroll();
    xinWenList().then(res => {
      if (res.data.code == 200) {
        this.newsXinWenItems = res.data.data.list
      }
    })
    hotList().then(res => {
      if (res.data.code == 200) {
        console.log(res.data.data)
        this.newsItems = res.data.data.data
        console.log(res)
      }
    })
  },
  computed: {
    chunkedNewsItems() {
      const chunks = [];
      for (let i = 0; i < this.newsXinWenItems.length; i += 4) {
        chunks.push(this.newsXinWenItems.slice(i, i + 4));
      }
      return chunks;
    },
    visibleNews() {
      return this.newsItems.slice(this.currentNewsIndex, this.currentNewsIndex + this.visibleNewsCount);
    }
  },
  methods: {
    nac(row) {
      console.log("11111", row)
    },
    goToAbout(article_id) {
      this.$router.push({
        name: 'Detail',
        params: { id: article_id }
      })
    },
    formatCreatedAt(createdAt) {
      // 使用 moment 解析时区并格式化时间
      return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
    },
    startNewsScroll() {
      setInterval(() => {
        this.currentNewsIndex = (this.currentNewsIndex + 1) % this.newsItems.length;
      }, 3000);
    },
    handleSearch() {
      console.log(this.searchValue)
      window.open(`https://www.baidu.com/s?wd=${encodeURIComponent(this.searchValue)}`, '_blank');
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      switch (key) {
        case '山西':
          this.$router.push({
            name: 'HeadlineHotList'
          })
          break;
        case '财经':
          this.$router.push({
            name: 'CaiJinHotList'
          })
          break;
        case '科技':
          this.$router.push({
            name: 'KejiHotList'
          })
          break;
        case '体育':
          this.$router.push({
            name: 'TiyuHotList'
          })
          break;
        case '国际':
          this.$router.push({
            name: 'GuojiHotList'
          })
          break;
        case '军事':
          this.$router.push({
            name: 'JunshiHotList'
          })
          break;
        case '推荐':
          this.$router.push({
            name: 'TuijinHotList'
          })
          break;
        case '娱乐':
          this.$router.push({
            name: 'YuleHotList'
          })
          break;
        case '历史':
          this.$router.push({
            name: 'LishiHotList'
          })
          break;
        case '美食':
          this.$router.push({
            name: 'MeishiHotList'
          })
          break;
        case '旅游':
          this.$router.push({
            name: 'LvyouHotList'
          })
          break;
        case '视频':
          this.$router.push({
            name: 'ShipingHotList'
          })
          break;
      }
    }
  },
}
</script>

<style>
@import '../css/index.04bb1327.css';
@import '../css/index-2024920105656.css';

.el-divider--vertical {
  width: 4px !important;
  height: 2em !important;

}

.el-divider {
  background-color: red !important;
}

.el-menu.el-menu--horizontal {
  width: 100% !important;
}

.el-card__header {
  padding: 15px 20px !important;
}

.swiper {
  width: 720px;
  height: 400px;
  display: flex;
  justify-content: flex-start;
}

.el-col-16 {
  width: 50% !important;
}

.el-input-group__append {
  background-color: transparent !important;
  ;
  border: none !important;
  ;
}

.el-header {
  padding: 0;
}

.news-ticker {
  height: 461px;
  overflow: hidden;
}

.news-container {
  height: 100%;
  overflow: hidden;
}

.news-container ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.news-container li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.news-list-enter-active,
.news-list-leave-active {
  transition: all 0.5s;
}

.news-list-enter,
.news-list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
